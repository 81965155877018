.company {
    padding: 50px 10px;
    max-width: 980px;
    margin: 0 auto;
}

.company_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company_block {
    width: 64%;
}

.company_logo {
    width: 300px;
    height: 110px;
}

.company_title {
    font-family: var(--font-bold);
    font-size: var(--text-title2-size);
    margin-bottom: 50px;
    text-align: center;
}

.company_text {
    margin: 10px;
    font-family: var(--font-thin);
    font-size: var(--text-size);
    text-align: justify;
}

@media (max-width: 767px) {

    .company_content {
        flex-wrap: wrap;
        justify-content: center;
    }

    .company_block {
        width: 100%;
    }

    .company_title {
        display: none;
    }

}

@media (max-width: 480px) {
    .company_logo {
        width: 90%;
    }
}

.markdowncontainer {

    font-family: var(--font-thin);
    font-size: var(--text-size);
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;

    ul {
        padding-left: 30px;
        margin-bottom: 4px;
    }

    li {
        list-style: circle;
    }
    
    li li {
      list-style-type: '-  ';
    }

    h1 {
        padding-top: 16px;
        font-size: 24px;
        font-weight: 800;
    }
    h2 {
        padding-top: 16px;
        font-size: 22px;
        font-weight: 600;
    }
    h3 {
        padding-top: 16px;
        font-size: 20px;
        font-weight: 600;
    }

    h4 {
        padding-top: 16px;
        font-size: 18px;
        font-weight: 400;
    }

    p {
        margin-bottom: 16px;
    }
  }

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-overlay-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    max-width: 80%;
    max-height: 100%;
}

@media (min-width: 1600px) {

    .modalContent {
        max-width: 75%;
    }
}
.intro {
    background-color: var(--bg-main-color);
}

.intro_banner {
    background-position: center;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1849px 793px;
    background-image: -Webkit-image-set(url(assets/Home/banner.jpg) 1x, url(assets/Home/banner@2x.jpg) 2x);
    height: 793px;
}

.intro_content {
    margin: 0 auto;
    max-width:45%;
    text-align: center;
    padding: 5% 0;
}

.intro_title {
    font-family: var(--font-bold);
    font-size: var(--text-title-size);
    padding-bottom: 14%;
}

.intro_text {
    font-family: var(--font-thin);
    font-size: var(--text-size);
    text-align: center;
    letter-spacing: 4px;
    line-height: 1.7;
    color: var(--text-secondary-color);
}

.intro_image {
    width: 100%;
    object-fit: cover;
}

@media (max-width: 992px) {
    .intro_content {
        max-width:60%;
    }

}

@media (max-width: 767px) {

    .intro_content {
        max-width: 90%;
        padding: 10% 0;
    }

    .intro_banner {
        background-image: url(assets/Home/banner_small.jpg);
        background-size: 734px 548px;
        height: 548px;
    }

    .intro_title {
        font-size: var(--text-title-size);
    }
}

@media (max-width: 480px) {


    .intro_title {
        font-size: var(--text-title2-size);
    }

    .intro_text {
        font-size: var(--text-caption-size);
    }

}

@media (max-width: 380px) {


    .intro_title {
        font-size: var(--text-title3-size);
    }

    .intro_text {
        font-size: var(--text-caption-size);
    }
}


.header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    font-family: var(--font-thin);
    background: var(--bg-header-color);
    backdrop-filter: blur(10px);
    z-index: 10;
}

.header_logo {
    width: 22px;
    height: 22px;
}

.header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 980px;
    padding: 0 10px;
}

.header_navigation {
    width: 85%;
}

.navigation_menu {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--text-size);
    line-height: 28px;
}

.navigation_link:hover,
.navigation_link:active, 
.navigation_link:focus  {
    color: var(--accent-color);
    outline: none;
}

.header_navigation_mobile {
    display: none;
}

.navigation_localisation {
    padding: 15px 0;
    font-size: var(--text-size);
    display: flex;
    cursor: pointer;
}

.localisation_icon{
    display: block;
}

.localisation_icon{
    padding: 0 5px;
}


.localisation_icon span:hover,
.localisation_icon span:active {
    box-shadow: var(--shadow);
    outline: none;
}


@media (max-width: 480px) {
    .header_content {
        justify-content: space-between;
    }

    .header_navigation {
        display: none;
    }

    .header_navigation_mobile {
        display: block;
    }

    .navigation_menu {
        padding: 0 10px;
        justify-content: space-around;
    }

}

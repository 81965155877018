
.contacts_list {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
}

.contacts_link {
    padding: 15px;
}

.contacts_link:hover,
.contacts_link:active,
.contacts_link:focus {
color: var(--accent-color);
transition: all 500ms ease; 
}

@media (max-width: 380px) {
    .contacts_link {
        padding: 12px;
    }
}

@media (max-width: 330px) {
    .contacts_link {
        padding: 7px;
    }
}

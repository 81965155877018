.badge {
    position: fixed;
    left: -80px;
    bottom: 20px;
    z-index: 11;
    width: 300px;
    height: 84px;
    background: linear-gradient(-180deg, var(--badge-top-color) 50%, var(--badge-bottom-color) 50%);
    -webkit-background: linear-gradient(-180deg, var(--badge-top-color) 50%, var(--badge-bottom-color) 50%);
    transform: rotate(45deg);
    cursor: pointer;
}

@media (max-width: 767px) {
    .badge {
        left: -140px;
        height: 42px;
    }
}

@media (max-width: 330px) {

    .badge {
        left: -155px;
        height: 30px;
    }
}


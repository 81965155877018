@import-normalize;

@font-face {
    font-family: "SFProDisplay-Thin";
    src: url("./assets/fonts/SFProDisplay-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "SFProDisplay-Light";
    src: url("./assets/fonts/SFProDisplay-Light.ttf") format("truetype");
}

@font-face {
    font-family: "SFProDisplay-Medium";
    src: url("./assets/fonts/SFProDisplay-Medium.ttf") format("truetype");
}

:root {
    --bg-header-color: #f5f5f780;
    --bg-page-color: #fff;
    --bg-main-color: #f5f5f7;

    --primary-color: #ffd500;
    --accent-color: #179ff9;

    --text-primary-color: #1d1d1f;
    --text-secondary-color: #515154;
    --text-white-color: #fff;

    --border-color: #ceced5;

    --pagination-color: var(--text-secondary-color);

    --scroll-btn: var(--primary-color);
    --scroll-btn-icon: var(--text-white-color);
    --scroll-btn-active: var(--accent-color);

    --badge-top-color: #005bbb;
    --badge-bottom-color: #ffd500;

    --untoggle-bg-color:#f5f5f7;
    --toggle-bg-color:#33cf4d;
    --switch-bg-color:#fff;

    --modal-overlay-bg-color:#000000cc;

    --text-title-size: 35px;
    --text-title2-size: 32px;
    --text-title3-size: 24px;
    --text-title4-size: 20px;

    --text-size: 18px;
    --text-caption-size: 16px;
    
    --border-radius: 12px;

    --lines: 1px solid var(--border-color);

    --shadow: 0 12px 16px 0 #0000003d,0 17px 50px 0 #00000030;

    --font-regular: "SFProDisplay-Light";
    --font-bold: "SFProDisplay-Medium";
    --font-thin: "SFProDisplay-Thin";
}

* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: var(--font-regular);
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background-color: var(--bg-page-color);
    scroll-behavior: auto;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a {
    padding: 15px 5px;
    color: var(--text-primary-color);
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: 400;
}

nav a.active {
    color: var(--accent-color);
}

.slick-slider {
    width: 100%;
}

.slick-list {
    position: relative;
    display: block !important;
    width: 100%;
}

.slick-slide div {
    width: auto;
    margin-right: 10px;
}

.slick-slide img {
    width: calc(100% - 10px) !important;
}

.slick-next{
    background: url(./assets/Poftfolio/buttons/icon-right.png) center center no-repeat!important;
}

.slick-next::before {
    display: none;
}

.slick-prev {
    background: url(./assets/Poftfolio/buttons/icon_prev.png) center center no-repeat!important;
}

.slick-prev::before {
    display: none;
}

.slick-prev:before,
.slick-next:before {
    color: var(--accent-color)!important;
}

.slick-prev,
.slick-next {
    z-index: 9 !important;
}
.slick-prev {
    left: -25px !important;
}
.slick-next {
    right: -25px !important;
}

.cooperation {
    padding: 32px 10px;
}

.cooperation_content {
    max-width: 940px;
    margin: 0 auto;
}

.cooperation_title {
    font-family: var(--font-bold);
    font-size: var(--text-title-size);
    text-align: center;
    margin-bottom: 32px;
}

.cooperation_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.cooperation_item {
    margin: 5px
}

.cooperation_image {
    height: 64px;
}

@media (max-width: 480px) {
    .cooperation_image {
        max-width: 256px;
    }
}


.products {
    padding: 50px 32px 100px 32px;
    max-width: 980px;
    margin: 0 auto;
    font-family: var(--font-thin);
}

.products_content {
    display: flex;
    flex-direction: column;
}

.products_title {
    font-family: var(--font-bold);
    font-size: var(--text-title2-size);
    padding: 10px 0;
    display: block;
    margin: 0 auto;
}

.products_form {
    border: var(--lines);
    border-radius: var(--border-radius);
    padding: 12px 43px 12px 12px;
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 48px;
}

.form_button {
    background-color: transparent;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
}

.form_icon {
    color: var(--text-secondary-color);
}

.form_text {
    font-size: var(--text-size);
}

.products_block {
    border-bottom: var(--lines);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.products_filter {
    display: flex;
    justify-content: center;
    padding: 12px 0 12px 0;
    font-size: var(--text-size);
}

.products_toggle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.products_toggle_text {
    font-size: var(--text-size);
}

.filter_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.active {
    color: var(--accent-color);
}


.filter_item:hover,
.filter_item:active,
.filter_item:focus {
    color: var(--accent-color);
    outline: none;
}

.filter_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    border: var(--lines);
    padding: 8px;
    margin: 0 20px 20px 20px;
}

.filter_img {
    height: 32px;
    width: 32px;
}

.products_list {
    display: flex;
    max-width: 980px;
    flex-wrap: wrap;
    margin-top: 32px;
}

.products_item {
    background-color: var(--bg-main-color);
    border-radius: var(--border-radius);
    font-family: var(--font-regular);
    font-size: var(--text-caption-size);
    text-align: center;
    cursor: pointer;
    width: 30%;
    margin: 15px;
}

.products_link {
    display: block;
    text-align: center;
}

.products_link:hover,
.products_link:active,
.products_link:focus{
    color: var(--accent-color);
    outline: none;
}

.products_image {
    display: block;
    width: 172px;
    margin: 24px auto;
}

.spinner_wrapper {
    width: 980px;
}

.spinner {
    height: 50vh;
}

@media (max-width: 992px) {
    .products_list {
        margin: 32px 100px;
    }

    .products_item {
        width: 43%;
    }

    .products_image {
        width: 130px;
    }

        
    .products_form {
        width: 85%;
    }
}

@media (max-width: 767px) {

    .products_list {
        justify-content: center;
        margin: 32px 25px;
    }

    .products_item {
        width: 60%;
    }

    .products_form {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .products_filter {
        flex-wrap: wrap;
        gap: 20px;
    }

    .form_text {
        font-size: var(--text-caption-size);
    }

    .products_list {
        margin: 32px 15px;
    }

    .products_item {
        width: 90%;
    }

    .products_image {
        width: 100px;
    }
}
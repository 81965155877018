.services {
    background-color: var(--bg-page-color);
    padding: 50px 0 150px 0;
}

.services_item {
    background-color: var(--bg-main-color);
}

.overlay{
    background: var(--bg-main-color);
}
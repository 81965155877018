.hidden {
    display: none;
}

.visible {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 11;
    height: 40px;
    width: 40px;
    background: var(--scroll-btn);
    color: var(--scroll-btn-icon);
    cursor: pointer;
}

.visible:hover {
    background-color:var(--scroll-btn-active);
    transition: all 0.3s ease-out;
}

@media (max-width: 767px) {
    .visible{
        bottom: 10px;
    }
}


@media (max-width: 330px) {
    .visible{
        height: 30px;
        width: 30px;
    }
}
.services {
    padding: 64px 0;
    background-color: var(--bg-main-color);
}

.services_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 32px;
    gap: 32px;
}

.services_item {
    background-color: var(--bg-page-color);
    border-radius: var(--border-radius);
    width: 48%;
    text-align: center;
    padding: 50px 25px;
    position: relative;
}

.services_title {
    font-family: var(--font-bold);
    font-size: var(--text-title2-size);
    padding: 12.5px 0;
}

.services_description {
    font-family: var(--font-thin);
    font-size: var(--text-size);
    padding: 12.5px 0;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    border-radius: var(--border-radius);
}

.services_item:hover .overlay {
    opacity: 1;
}

.overlay_text{
    font-family: var(--font-bold);
    font-size: var(--text-title2-size);
    margin-bottom: 25px;
}

.overlay_content {
    width: 100%;
    position: absolute;
    bottom: 0%;
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    transition: 0.5s;
    -webkit-transition: 0.5s;
}

.services_item:hover .overlay_content {
    bottom: 20%;
    opacity: 1;
}

@media (max-width: 860px) {
    .services_list {
        align-items: center;
        flex-direction: column;
        gap: 25px;
        padding: 0 25px;
    }

    .services_item {
        width: 84%;
    }
}

@media (max-width: 650px) {
    .services_item:hover .overlay_content {
        bottom: 25%;
    }

}


@media (max-width: 400px) {
    .services_title {
        font-size: var(--text-title3-size);
    }
}

@media (max-width: 325px) {
    .services_title {
        font-size: var(--text-title4-size);
    }

    .services_item:hover .overlay_content {
        bottom: 30%;
    }
}




.portfolioItem {
    padding: 32px 0 96px 0;
    max-width: 980px;
    margin: 0 auto;
    font-family: var(--font-bold);
}

.portfolioItem_primary {
    display: flex;
    justify-content: space-between;
    margin: 0 32px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: var(--lines);
}

.primary_general {
    display: flex;
    align-items: center;
}

.general_icon {
    width: 200px;
    padding: 32px;
}

.general_title {
    padding: 8px 0;
    font-size: var(--text-title2-size);
}

.general_category {
    padding: 12px 0;
    font-size: var(--text-size);
    color: var(--text-secondary-color);
}

.general_link {
    display: inline-block;
    text-align: center;
    padding: 12px 64px;
    margin: 8px 0;
    font-size: var(--text-size);
    color: var(--text-white-color);
    background-color: var(--accent-color);
    border-radius: var(--border-radius);
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
}

.general_link:hover,
.general_link:active,
.general_link:focus {
    box-shadow: var(--shadow);
    outline: none;
}

.primary_list {
    display: flex;
    text-transform: uppercase;
    font-size: var(--text-size);
    color: var(--text-secondary-color);
}

.primary_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px;
}

.item_title {
    padding: 4px 0;
}

.item_content {
    padding: 4px 0;
    font-size: var(--text-title3-size);
}

.item_other {
    padding: 4px 0;
}

.primary_concretization {
    padding-bottom: 32px;
    padding-top: 32px;
    margin: 0px 16px;
    border-bottom: var(--lines);
}

.concretization_slider {
    padding: 16px;
}

.concretization_image:hover {
    cursor: zoom-in;
}

.concretization_icon {
    width: 40px;
    height: 40px;
    padding: 0 0 8px 0;
    margin: 0 auto;
}

.primary_rating {
    display: flex;
    align-items: center;
    padding: 24px 0;
    margin: 0 16px;
    border-bottom: var(--lines);
}

.rating_title {
    margin-right: 32px;
    font-size: var(--text-title3-size);
}

.rating_image {
    width: 128px;
}

.primary_information {
    padding: 16px 0;
    margin: 0 16px;
}

.information_title {
    padding: 8px 0 16px 0;
    font-size: var(--text-title3-size);
}

.information_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-size);
}

.information_item {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
}

.information_text:first-child {
    margin-right: 4px;
}

.information_link {
    color: var(--accent-color);
}

.information_link:hover,
.information_link:active,
.information_link:focus {
    text-decoration: underline;
    outline: none;
}

.information_icon {
    margin-right: 8px;
}

@media (max-width: 992px) {

    .general_category {
        padding: 4px 0;
    }

    .primary_item {
        margin: 0 24px;
    }
}

@media (max-width: 767px) {

    .portfolioItem_primary {
        flex-direction: column;
        align-items: flex-start;
    }

    .primary_list {
        justify-content: space-between;
        width: 100%;
    }

    .general_category {
        padding: 4px 0;
    }

    .primary_item {
        margin: 0 16px;
    }

    .item_content {
        padding: 4px 0;
    }
}

@media (max-width: 480px) {

    .primary_general {
        flex-direction: column;
    }

    .general_icon {
        width: 86%;
    }

    .primary_list {
        margin: 0 auto;
    }

    .concretization_icon {
        display: block;
        width: 48px;
        height: 48px;
        padding: 0 0 8px 0;
        margin: 0 auto;
    }

    .general_block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .primary_list {
        margin: 0 auto;
        padding: 10px 0;
    }

}

@media (max-width: 400px) {

    .information_list{
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .information_list li{
        padding: 10px 0;
    }

    .information_link {
        padding: 16px 0; 
    }

}

.footer {
    position: fixed;
    bottom: -57px;
    width: 100%;
    background-color: var(--bg-header-color);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 10;
    transition: all 0.5s;
}

.footer:hover,
.footer:active {
    bottom: 0px;
    transition: all 0.5s;
}

.footer_content {
    padding: 15px 10px;
}

.footer_copyright {
    font-family: var(--font-thin);
    font-size: var(--text-size);
    color: var(--text-secondary-color);
    text-align: center;
    font-weight: 400;
    line-height: 1;
}

@media (max-width: 330px) {
    .footer_copyright {
        font-size: var(--text-caption-size);
    }
}

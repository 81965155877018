.feedback {
    padding: 32px 10px;
    background-color: var(--bg-main-color);
    font-family: var(--font-bold);
}

.feedback_content {
    max-width: 980px;
    margin: 0 auto;
}

.feedback_title {
    text-align: center;
    font-size: var(--text-title2-size);
    margin-bottom: 32px;
    text-align: center;
}

.feedback_list {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: var(--text-size);
}

.feedback_item {
    background-color: var(--bg-page-color);
    border-radius: var(--border-radius);
    margin: 15px;
    padding: 25px;
    width: 30%;
}

.feedback_message {
    font-family: var(--font-regular);
    color: var(--text-secondary-color);
    text-align: justify;
}

.feedback_person {
    display: flex;
    align-items: center;
    margin-top: 25px;
    border-top: var(--lines);
    padding-top: 15px;
}

.feedback_image {
    width: 50px;
}

.feedback_person_info {
    margin-left: 15px;
}

.feedback_name{
    padding-bottom: 2px;
}

.feedback_position{
    padding-top: 2px;
    font-family: var(--font-thin);
}

@media (max-width: 992px) {
    .feedback_item {
        width: 45%;
    }
}

@media (max-width: 767px) {
    .feedback_item {
        width: 80%;
    }
}

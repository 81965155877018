.gallery {
    padding: 50px 10px 150px 10px;
}

.gallery_slide {
    padding: 0px 0 40px 0;
    margin-right: 40px;
}

.gallery_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: var(--shadow);
    -webkit-box-shadow: var(--shadow);
}

.gallery_swiper {
    --swiper-pagination-color: var(--pagination-color);
}


.toggler {
  width: 42px;
  height: 26px;
  background-color: var(--untoggle-bg-color);
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  position: relative;
  transition: background-color 0.3s; 
}

.toggle_switch {
  position: absolute;
  left: 2px;
  width: 22px;
  height: 22px;
  background-color: var(--switch-bg-color);
  border-radius: 50%;
  transition: transform 300ms;
  -webkit-transition: -webkit-transform 300ms;

}

.toggled .toggle_switch {
  transform: translateX(16px);
  -webkit-transform: translateX(16px);
}

.toggler.toggled {
  background-color: var(--toggle-bg-color); 
}

.toggler:not(.toggled) {
  background-color: var(--untoggle-bg-color); 
}
.person {
    padding: 50px 32px 100px 32px;
    max-width: 980px;
    margin: 0 auto;
}

.person_content {
    display: flex;
    justify-content: space-around;
}

.person_img {
    display: block;
    width: 320px;
    height: 320px;
    border-radius: var(--border-radius);
    margin-right: 10px;
}

.person_info {
    background-color: var(--bg-main-color);
    border-radius: var(--border-radius);
    margin-left: 10px;
    padding: 17px 0;
    font-family: var(--font-bold);
}

.person_info:nth-first {
    align-items: start;
}

.person_name {
    font-family: var(--font-bold);
    font-size: var(--text-title2-size);
    margin: 20px;
    text-align: center;
}

.person_experience {
    font-family: var(--font-regular);
    font-size: var(--text-size);
    margin: 20px;
}

.person_list {
    display: flex;
    align-items: baseline;
    justify-content: right;
    margin: 20px 0;
}

.person_link {
    margin: 0 10px;
}

@media (max-width: 767px) {
    .person {
        padding: 50px 32px 150px 32px;
    }

    .person_content {
        flex-direction: column;
        align-items: center; 
    }

    .person_content li {
        margin: 15px 0;
    }

    .person_img {
        margin-right: 0;
    }

    .person_info {
        padding: 25px;
    }
}

@media (max-width: 480px) {

    .person_content {
        flex-direction: column;
    }

    .person_content li {
        margin: 15px 0;
    }

    .person_img {
        margin-right: 0;
        width: 280px;
        height: 280px;
    }

    .person_info {
        padding: 25px;
    }
}

@media (max-width: 280px) {

    .person_img {
        width: 250px;
        height: 250px;
    }

}